import { useWindowSize } from "hook/useWindowSize";
import "./Transaction.css";
import { formatPrice, formatDatetime } from "util/Fomatter";

export enum TransactionType {
  DEPOSIT = "deposit",
  CONVERT = "convert",
}

type CommonTxProps = {
  message: string;
  timestamp: string;
  amount: number;
  unit: string;
};

type ConvertedTx = CommonTxProps & {
  subAmount: number;
  subUnit: string;
  price: string;
};

type DepositedTx = CommonTxProps & {
  txId: string;
};

export type TransactionProps = ConvertedTx | DepositedTx;

const Transaction = ({ tx }: { tx: TransactionProps }) => {
  const isDeposited = "txId" in tx;
  const { width } = useWindowSize();

  const handleTransactionClick = () => {
    if (isDeposited) {
      // DepositedTx
      window.open(`https://tronscan.org/#/transaction/${tx.txId}`, "_blank");
    }
  };

  return (
    <div>
      <div className="tx-item" onClick={handleTransactionClick}>
        {width > 600 ? (
          <div style={{ borderBottom: "1px solid white" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1 }}>
                <p>{tx.message}</p>
              </div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <p
                  className={
                    isDeposited ? "nes-text is-error" : "nes-text is-warning"
                  }
                >
                  {formatPrice(String(tx.amount))}{" "}
                  <span style={{ color: "white" }}>
                    {tx.unit.toUpperCase()}
                  </span>
                </p>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ flex: 1 }}>
                <p style={{ color: "gray", fontSize: 13 }}>
                  {formatDatetime(tx.timestamp)}
                </p>
              </div>
              <div style={{ flex: 1, textAlign: "right" }}>
                <p style={{ color: "gray", fontSize: 13 }}>
                  {isDeposited
                    ? ""
                    : `-${formatPrice(
                        String(tx.subAmount)
                      )} ${tx.subUnit.toUpperCase()}`}
                </p>
                <p style={{ color: "gray", fontSize: 12, marginTop: -12}}>
                  {isDeposited
                    ? ""
                    : `(Price: USD ${formatPrice(String(tx.price))})`}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ borderBottom: "1px solid white" }}>
            <p style={{ color: "gray", fontSize: 10, margin: 0 }}>
              {formatDatetime(tx.timestamp)}
            </p>
            <div style={{ fontSize: 14, marginTop: 4 }}>
              {isDeposited ? "Deposited shit" : "Converted to sats"}
            </div>
            <div style={{ flex: 1, textAlign: "right", marginTop: "4px" }}>
              <p
                className={
                  isDeposited ? "nes-text is-error" : "nes-text is-warning"
                }
                style={{ fontSize: 14, margin: 0 }}
              >
                {formatPrice(String(tx.amount))}{" "}
                <span style={{ color: "white" }}>{tx.unit.toUpperCase()}</span>
              </p>
              <p
                style={{
                  flex: 1,
                  textAlign: "right",
                  color: "gray",
                  fontSize: 10,
                  marginTop: 4,
                }}
              >
                {isDeposited
                  ? ""
                  : `-${formatPrice(
                      String(tx.subAmount)
                    )} ${tx.subUnit.toUpperCase()}`} 
              </p>
              <p style={{
                  flex: 1,
                  textAlign: "right",
                  color: "gray",
                  fontSize: 10,
                  marginTop: -12,
                }}>
                  {isDeposited
                    ? ""
                    : `(Price: USD ${formatPrice(String(tx.price))})`}
                </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Transaction;
