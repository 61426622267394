import React, { ReactNode, useEffect, useState } from "react";
import "./Banner.css";

interface BannerProps {
  children: ReactNode;
}

const Banner: React.FC<BannerProps> = ({ children }) => {
  const childrenArray = React.Children.toArray(children);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % childrenArray.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [childrenArray.length, currentIndex]);

  return (
    <div className="rolling-banner-container">
      {/* <div className="rolling-banner">
        <div className="rolling-text">{children}</div>
      </div> */}
      {childrenArray.map((child: any, index: number) => (
        <div
          key={index}
          className={index === currentIndex ? "visible" : "hidden"}
          style={{ display: index === currentIndex ? "block" : "none" }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

export default Banner;
