import { useNavigate } from "react-router-dom";
import "nes.css/css/nes.min.css";
import Layout from "components/layout/Layout";
import Calculator from "components/Calculator";
import { useWindowSize } from "hook/useWindowSize";
import { MOBILE_WIDTH } from "const/Constants";
import Button from "components/Button";
import { isUser } from "localStorage/token";

function Main() {
  const navigate = useNavigate();
  const { width } = useWindowSize();

  const handleButtonClick = () => {
    if (isUser()) {
      navigate("/mypage");
      return;
    }

    navigate("/auth-step-1");
  };

  return (
    <Layout>
      <div
        style={{
          flex: "1",
          display: "flex",
          flexDirection: width > MOBILE_WIDTH ? "row" : "column",
        }}
      >
        <Calculator />
      </div>

      {/* Greeting */}
      <div
        style={{
          marginTop: "52px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img
          src="/image/smile.png"
          alt="smile"
          style={{
            width: "30px",
            height: "30px",
            marginRight: "12px",
          }}
        />
        <h2 className="title">Welcome</h2>
      </div>

      <div className="nes-container is-dark" style={{ textAlign: "left" }}>
        <div style={{ padding: width > MOBILE_WIDTH ? "32px" : "8px" }}>
          <div>
            <p style={{ fontSize: width > MOBILE_WIDTH ? "18px" : "14px" }}>
              Hi, there!
            </p>
          </div>
          <div>
            <p style={{ fontSize: width > MOBILE_WIDTH ? "18px" : "14px" }}>
              We are currently converting Tron into Satoshi.
            </p>
          </div>
          <div>
            <p style={{ fontSize: width > MOBILE_WIDTH ? "18px" : "14px" }}>
              We hope you enjoy using our service.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "18px",
        }}
      >
        <Button label="START" onClick={handleButtonClick} />
      </div>
    </Layout>
  );
}

export default Main;
