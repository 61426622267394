import { api } from "api/Shit2Sat";
import { MOBILE_WIDTH } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import usePriceStore, { useExchangeRateStore } from "store/priceStore";
import { getDepositRange } from "util/DepositRange";
import { formatPrice } from "util/Fomatter";

const Calculator = () => {
  const { width } = useWindowSize();
  const { bitcoin, tron, bitcoinKRW, tronKRW, setBitcoin, setTron } =
    usePriceStore();
  const { trxBtcRate, krwUsdRate } = useExchangeRateStore();
  const [selectedOption, setSelectedOption] = useState("fee");
  const [quantity, setQuantity] = useState<number | null>(50);
  const [s2sFee, setS2sFee] = useState(1.5);
  // const [preferFee, setPreferFee] = useState(1);
  const [exchangeRate, setExchangeRate] = useState<string | null>(null);
  const [kimpBtc, setKimpBtc] = useState<string | null>(null);
  const [kimpTrx, setKimpTrx] = useState<string | null>(null);

  const minTronCount = useMemo<string | null>(() => {
    if (!tron) return null;

    return getDepositRange(tron).min;
  }, [tron]);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseFloat(e.target.value);
    if (isNaN(newQuantity)) {
      setQuantity(null);
      return;
    }

    const formattedQuantity = parseFloat(newQuantity.toFixed(6));
    setQuantity(parseFloat(formattedQuantity.toString().replace(/^0+/, "")));
  };

  const calculateBtcAmount = (): string => {
    if (quantity === null || isNaN(quantity)) {
      return "";
    }

    if (trxBtcRate === null) {
      return "Loading...";
    }

    const appliedQuantity = quantity;
    return (
      appliedQuantity *
      parseFloat(trxBtcRate.toString()) *
      (1 - s2sFee / 100)
    ).toFixed(8); // Format to 8 decimal places
  };

  useEffect(() => {
    const getFeeRate = () => {
      api
        .getFeeRate()
        .then((response) => {
          setS2sFee(response.data.currentFee);
        })
        .catch(console.error);
      // api
      //   .getPreferentialFeeRate(4000)
      //   .then((response) => {
      //     console.log(response.data);
      //     setPreferFee(response.data.currentFee);
      //   })
      // .catch(console.error);
    };
    
    getFeeRate();

    if (bitcoin === null) {
      api.getMarketPrice("BTCUSDC").then((r) => {
        setBitcoin(r.data.toString());
      });
    }

    if (tron === null) {
      api.getMarketPrice("TRXUSDC").then((r) => {
        setTron(r.data.toString());
      });
    }
  }, []);

  useEffect(() => {
    if (krwUsdRate) {
      setExchangeRate(krwUsdRate);
    }

    if (bitcoin && bitcoinKRW && krwUsdRate) {
      setKimpBtc(
        (
          parseFloat(bitcoinKRW) / parseFloat(krwUsdRate) -
          parseFloat(bitcoin)
        ).toString()
      );
    }

    if (tron && tronKRW && krwUsdRate) {
      setKimpTrx(
        (
          parseFloat(tronKRW) / parseFloat(krwUsdRate) -
          parseFloat(tron)
        ).toLocaleString()
      );
    }
  }, [bitcoin, tron, bitcoinKRW, tronKRW, krwUsdRate]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const renderContent = () => {
    return (
      <div>
        {renderSwitch()}
      </div>
    );
  };

  const renderSwitch = () => {
    switch(selectedOption) {
      case "Estimate":  
        return renderExchange();
      case "kimp":
        return renderKimp();
      case "fee":
        return renderFeeRateInfo();
    }
  }

  const renderExchange = () => {
    return (
      <div>
        <p style={{ marginLeft: "auto", fontSize: 12, marginTop: 20 }}>
          * The estimate below is based on {s2sFee}% fee from Tron network.
        </p>
        <p style={{ marginLeft: "auto", fontSize: 12, marginTop: -6 }}>
          * The minimum deposit amount is {minTronCount ? minTronCount : "..."}{" "}
          TRX.{minTronCount && minTronCount !== "50" && "(about $5.5)"}
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: width > MOBILE_WIDTH ? "row" : "column",
          }}
        >
          <div
            className="nes-field"
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            <div style={{ flex: 9 }}>
              <input
                type="number"
                id="quantity"
                className="nes-input"
                value={quantity === null ? "" : quantity}
                onChange={handleQuantityChange}
                min="0"
              />
            </div>
            <div style={{ flex: 1 }}>
              <label
                htmlFor="quantity"
                style={{ marginTop: "16px", marginLeft: "4px" }}
              >
                TRX
              </label>
            </div>
          </div>
          {width > MOBILE_WIDTH && (
            <div>
              <img
                src="/image/arrow_right.png"
                alt="Arrow Right"
                style={{
                  width: "32px",
                  height: "32px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
              />
            </div>
          )}
          <div
            className="nes-field"
            style={{
              flex: 1,
              display: "flex",
              marginTop: width > MOBILE_WIDTH ? 0 : "12px",
            }}
          >
            <div style={{ flex: 9 }}>
              <input
                type="text"
                id="btcAmount"
                className="nes-input"
                value={calculateBtcAmount()}
                readOnly
              />
            </div>
            <div
              style={{
                flex: 1,
              }}
            >
              <label
                htmlFor="quantity"
                style={{ marginTop: "16px", marginLeft: "4px" }}
              >
                BTC
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderKimp = () => {
    return (
      <div className="nes-table-responsive">
        <div style={{ display: "flex", marginTop: 20 }}>
          <p style={{ marginRight: "auto", fontSize: 12 }}>
            (1 USD =
            {exchangeRate ? " " + formatPrice(exchangeRate.toString()) : "..."}{" "}
            KRW)
          </p>
        </div>
        <table className="nes-table is-bordered is-centered">
          <thead>
            <tr>
              <th></th>
              <th>KRW</th>
              <th>USD</th>
              <th>Kimchi Premium</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>BTC</td>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 16 : 11,
                  }}
                >
                  <div>{bitcoinKRW ? formatPrice(bitcoinKRW) : "..."}</div>
                  <div style={{ fontSize: width > MOBILE_WIDTH ? 12 : 9 }}>
                    ($
                    {bitcoinKRW && exchangeRate
                      ? formatPrice(
                          (
                            parseFloat(bitcoinKRW) / parseFloat(exchangeRate)
                          ).toString(),
                          2
                        )
                      : "..."}
                    )
                  </div>
                </div>
              </td>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 16 : 11,
                  }}
                >
                  {bitcoin ? formatPrice(bitcoin, 2) : "..."}
                </div>
              </td>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 18 : 12,
                  }}
                >
                  {kimpBtc && bitcoin
                    ? formatPrice(
                        (
                          (parseFloat(kimpBtc) / parseFloat(bitcoin)) *
                          100
                        ).toString(),
                        2
                      )
                    : "..."}
                  %
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 12 : 9,
                  }}
                >
                  (₩
                  {kimpBtc && exchangeRate
                    ? formatPrice(
                        (
                          parseFloat(kimpBtc) * parseFloat(exchangeRate)
                        ).toString(),
                        2
                      )
                    : "..."}
                  )
                </div>
              </td>
            </tr>
            <tr>
              <td>TRX</td>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 16 : 11,
                  }}
                >
                  <div>{tronKRW ? formatPrice(tronKRW) : "..."}</div>
                  <div style={{ fontSize: width > MOBILE_WIDTH ? 12 : 9 }}>
                    ($
                    {tronKRW && exchangeRate
                      ? formatPrice(
                          (
                            parseFloat(tronKRW) / parseFloat(exchangeRate)
                          ).toString(),
                          5
                        )
                      : "..."}
                    )
                  </div>
                </div>
              </td>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 16 : 11,
                  }}
                >
                  {tron ? formatPrice(tron) : "..."}
                </div>
              </td>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 18 : 12,
                  }}
                >
                  {kimpTrx && tron
                    ? formatPrice(
                        (
                          (parseFloat(kimpTrx) / parseFloat(tron)) *
                          100
                        ).toString(),
                        2
                      )
                    : "..."}
                  %
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: width > MOBILE_WIDTH ? 12 : 9,
                  }}
                >
                  (₩
                  {kimpTrx && exchangeRate
                    ? formatPrice(
                        (
                          parseFloat(kimpTrx) * parseFloat(exchangeRate)
                        ).toString(),
                        2
                      )
                    : "..."}
                  )
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderFeeRateInfo = () => {
    return (
      <div>
        <p style={{ marginLeft: "auto", fontSize: 12, marginTop: 20 }}>
          * The current basic fee is {s2sFee}%.
        </p>
        <p style={{ marginLeft: "auto", fontSize: 12, marginTop: -6 }}>
          * When trading more than <span style={{color: "#219CF0"}}>4000trx</span>, a preferential fee of <span style={{color: "#219CF0"}}>{1}%</span> is applied.
        </p>
        <p style={{ marginLeft: "auto", fontSize: 12, marginTop: -6 }}>
          * When making the first transaction, an additional <span style={{color: "#219CF0"}}>1.1trx</span> will be deducted according to Tron's own policy.
        </p>
      </div>
    );
  };

  return (
    <div
      className="nes-container"
      style={{ flex: "2", marginTop: width > MOBILE_WIDTH ? 0 : "12px" }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "auto" }}>
          <label>
            <input
              type="radio"
              className="nes-radio"
              name="answer"
              value="Estimate"
              checked={selectedOption === "Estimate"}
              onChange={handleRadioChange}
            />
            {selectedOption === "Estimate" ? (
              <span className="nes-text">Estimate</span>
            ) : (
              <span className="nes-text is-disabled">Estimate</span>
            )}
          </label>

          <label style={{ marginLeft: width > MOBILE_WIDTH ? 8 : 0 }}>
            <input
              type="radio"
              className="nes-radio"
              name="answer"
              value="kimp"
              checked={selectedOption === "kimp"}
              onChange={handleRadioChange}
            />
            {selectedOption === "kimp" ? (
              <span className="nes-text">Kimchi Premium</span>
            ) : (
              <span className="nes-text is-disabled">Kimchi Premium</span>
            )}
          </label>

          <label style={{ marginLeft: width > MOBILE_WIDTH ? 8 : 0 }}>
            <input
              type="radio"
              className="nes-radio"
              name="answer"
              value="fee"
              checked={selectedOption === "fee"}
              onChange={handleRadioChange}
            />
            {selectedOption === "fee" ? (
              <span className="nes-text">Fee Rate</span>
            ) : (
              <span className="nes-text is-disabled">Fee Rate</span>
            )}
          </label>
          
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default Calculator;
