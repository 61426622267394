import { useState, useRef, useLayoutEffect } from "react";
import Layout from "components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "hook/useWindowSize";
import { isEmail } from "util/Email";
import { api } from "api/Shit2Sat";
import CustomDialog from "components/dialog/CustomDialog";
import Button from "components/Button";

const LnAddressInput = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const { width } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDialogElement>(null);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleButtonClick = () => {
    const callApi = () => {
      setLoading(true);

      api
        .requestAuthCode(address.trim())
        .then((response) => {
          if (response?.data.success) {
            let authCode = "";

            if (process.env.NODE_ENV === "development") {
              authCode = response?.data.lightningAddress.split("|")[1];
            }

            const state = {
              lnAddress: address.trim(),
              authCode: authCode,
            };

            navigate("/auth-step-2", { state });
          }
        })
        .catch((error) => {
          setIsValid(false);
          if (error?.response) {
            setErrorMessage(error.response?.data.message);
            // setTimeout(() => {
            ref.current?.showModal();
            // }, 300);
          } else if (error.request) {
            alert(`No response received...`);
          } else {
            alert("Error occured before requesting");
          }
          // console.log("Error config:", error.config);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    callApi();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAddress(inputValue);

    const trimmed = inputValue.trim();
    let isValid = true;
    let errorMessage = "";

    if (trimmed === "") {
      isValid = false;
      errorMessage = "Address cannot be empty.";
    } else if (!isEmail(trimmed)) {
      isValid = false;
      errorMessage = "Invalid format of address";
    } else if (trimmed.endsWith("@speed.app")) {
      isValid = false;
      errorMessage = "Speed App is not supported.";
    } else if (trimmed.endsWith("@blink.sv")) {
      isValid = false;
      errorMessage = "Blink App is not supported.";
    }

    setIsValid(isValid);
    setErrorMessage(errorMessage);
  };

  useLayoutEffect(() => {
    if (inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Layout>
      <div>
        <div className="nes-field">
          <label htmlFor="ln-address">
            <div
              style={{
                marginTop: "52px",
                marginBottom: "20px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src="/image/lightning.jpg"
                alt="lightning"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "4px",
                }}
              />
              <h2 className="title" style={{ marginTop: "8px" }}>
                Lightning Address
              </h2>
            </div>
          </label>
          <input
            id="ln-address"
            className={isValid ? "nes-input" : "nes-input is-error"}
            ref={inputRef}
            type="email"
            value={address}
            onChange={handleInputChange}
          />
        </div>

        <div
          className="is-warning"
          style={{ marginTop: "12px", height: "40px" }}
        >
          <p style={{ color: "#E86F56", fontSize: width > 600 ? 18 : 12 }}>
            {errorMessage}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "18px",
          }}
        >
          <div>
            <button className="nes-btn" onClick={handleBackButtonClick}>
              BACK
            </button>
          </div>
          <div>
            <Button
              label="NEXT"
              onClick={handleButtonClick}
              disabled={!isValid || loading}
            />
          </div>
        </div>
        <CustomDialog
          ref={ref}
          message={errorMessage}
          buttonName="CLOSE"
          onClickButton={() => {
            ref.current?.close();
          }}
        />
        {process.env.NODE_ENV === "development" && (
          <button
            className="nes-btn"
            style={{ marginTop: 14 }}
            onClick={() => {
              api
                .resetDailyAuthLimit(address.trim())
                .then((response) => {
                  console.log(response?.data.success);
                })
                .catch((error) => {
                  console.error(error);
                });
            }}
          >
            RESET DAILY LIMIT FOR DEVELOPER
          </button>
        )}
      </div>
    </Layout>
  );
};

export default LnAddressInput;
