import { useRef, useState } from "react";
import Layout from "components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "hook/useWindowSize";
import { useLocation } from "react-router-dom";
import { MOBILE_WIDTH } from "const/Constants";
import Button from "components/Button";
import CountdownTimer from "components/CountdownTimer";
import { api } from "api/Shit2Sat";
import CustomDialog from "components/dialog/CustomDialog";
import { saveToken } from "localStorage/token";
import { saveLnAddress, saveTronAddress } from "localStorage/address";
import useContextStore, { CONTEXT } from "store/contextStore";

const AuthCode = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { lnAddress } = location.state;
  const [isValid, setIsValid] = useState(true);
  // const [authcode, setAuthcode] = useState(
  //   process.env.NODE_ENV === "development" ? location.state.authCode : ""
  // );
  const [authcode, setAuthcode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { width } = useWindowSize();

  const [timeLimitInMinutes] = useState(5);
  const [countdownRunning, setCountdownRunning] = useState(true);
  const [isExpired, setIsExpired] = useState(false);

  const [loading, setLoading] = useState(false);
  const ref = useRef<HTMLDialogElement>(null);

  const { context } = useContextStore();
  const setContext = useContextStore((state) => state.setContext);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleButtonClick = () => {
    const callApi = () => {
      setLoading(true);
      //setCountdownRunning(false);

      api
        .verifyAuthNumber(lnAddress, authcode)
        .then((response) => {
          saveToken(response.data.accessToken);
          saveLnAddress(lnAddress);

          if (context === CONTEXT.REREW_TRON) {
            api
              .createWallet()
              .then((response) => {
                const tronAddress = response?.data.address;
                saveTronAddress(tronAddress);
              })
              .catch((error) => {
                if (error?.response) {
                  setErrorMessage(error.response?.data.message);
                  ref.current?.showModal();
                } else if (error.request) {
                  alert(`No response received...`);
                } else {
                  alert("Error occured before requesting");
                }
              })
              .finally(() => {
                setContext(CONTEXT.NONE);
              });

            return;
          }
          navigate("/mypage");
        })
        .catch((error) => {
          setIsValid(false);
          if (error?.response) {
            setErrorMessage(error.response?.data.message);
            ref.current?.showModal();
          } else if (error.request) {
            alert(`No response received...`);
          } else {
            alert("Error occured before requesting");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    callApi();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setAuthcode(inputValue);

    if (isExpired) {
      setIsValid(false);
      setErrorMessage("The code has been expired.");
      return;
    }

    if (inputValue.trim() !== "") {
      setIsValid(true);
      setErrorMessage("");
    } else {
      setIsValid(false);
      setErrorMessage("That's not the right code. Try again.");
    }
  };

  const handleCountdownFinish = () => {
    setCountdownRunning(false);
    setIsExpired(true);
    setErrorMessage("The code has been expired.");
  };

  return (
    <Layout>
      <div>
        <div className="nes-container is-dark" style={{ textAlign: "left" }}>
          <div style={{ padding: width > MOBILE_WIDTH ? "32px" : "0px" }}>
            <div>
              <p style={{ fontSize: width > MOBILE_WIDTH ? "18px" : "14px", overflowWrap: 'break-word' }}>
                A verification code has been sent to{" "}
                <span style={{ color: "#219CEF" }}>{lnAddress}</span>.
              </p>
            </div>
            <div>
              <p style={{ fontSize: width > MOBILE_WIDTH ? "18px" : "14px" }}>
                This code will be valid for {timeLimitInMinutes} minutes.
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "52px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img
            src="/image/lightning.jpg"
            alt="lightning"
            style={{
              width: "48px",
              height: "48px",
              marginRight: "4px",
            }}
          />
          <h2
            className="title"
            style={{
              fontSize: width > MOBILE_WIDTH ? "24px" : "16px",
              marginTop: "8px",
            }}
          >
            Authentication Code
          </h2>
        </div>

        <div style={{ display: "flex" }}>
          <input
            className={isValid ? "nes-input" : "nes-input is-error"}
            id="authcode"
            type="text"
            value={authcode}
            onChange={handleInputChange}
            style={{ marginRight: 12 }}
          />
          <CountdownTimer
            second={timeLimitInMinutes * 60}
            running={countdownRunning}
            onFinish={handleCountdownFinish}
          />
        </div>

        <div
          className="is-warning"
          style={{ marginTop: "12px", height: "40px" }}
        >
          <p style={{ color: "#E86F56" }}>{errorMessage}</p>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "18px",
          }}
        >
          <button className="nes-btn" onClick={handleBackButtonClick}>
            BACK
          </button>
          <div>
            <Button
              label="NEXT"
              onClick={handleButtonClick}
              disabled={
                isExpired || !isValid || authcode.length === 0 || loading
              }
            />
          </div>
        </div>
        <CustomDialog
          ref={ref}
          message={errorMessage}
          buttonName="CLOSE"
          onClickButton={() => {
            ref.current?.close();
          }}
        />
      </div>
    </Layout>
  );
};

export default AuthCode;
