import { create } from "zustand";

export type setValue = (status: string) => void;

interface SystemStatusStore {
  status: string | null;
  setStatus: setValue;
  s2sWebSocket: WebSocket | null;
  setS2sWebSocket: (ws: WebSocket) => void;
  channelCapacity: string | null;
  setChannelCapacity: setValue;
}

const useSystemStatusStore = create<SystemStatusStore>((set) => ({
  status: null,
  setStatus: (status: string) => set({ status: status }),
  s2sWebSocket: null,
  setS2sWebSocket: (ws: WebSocket) => set({ s2sWebSocket: ws }),
  channelCapacity: null,
  setChannelCapacity: (value: string) => set({ channelCapacity: value }),
}));

export default useSystemStatusStore;
