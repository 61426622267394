export const formatPrice = (value: string, decimals?: number): string => {
  const numericValue = parseFloat(value);
  const isInteger = Number.isInteger(numericValue);

  if (isInteger) {
    if (!decimals) {
      return String(Math.round(numericValue)).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
    }
    const formattedValue = numericValue.toFixed(decimals);
    return formattedValue.replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  const decimalCount = (value.split(".")[1] || "").length;
  const formattedValue = numericValue.toFixed(
    decimals ? decimals : decimalCount
  );
  return formattedValue.replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const formatDatetime = (timestamp: number | string): string => {
  let date: Date = typeof timestamp === 'number' ? new Date(timestamp * 1000) : new Date(timestamp);

  const year: number = date.getFullYear();
  const month: string = ("0" + (date.getMonth() + 1)).slice(-2);
  const day: string = ("0" + date.getDate()).slice(-2);
  const hours: string = ("0" + date.getHours()).slice(-2);
  const minutes: string = ("0" + date.getMinutes()).slice(-2);
  const seconds: string = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
