import { create } from "zustand";

export type SetContext = (context: CONTEXT) => void;

export enum CONTEXT {
  NONE,
  REREW_TRON,
}

interface ContextStore {
  context: CONTEXT;
  setContext: SetContext;
}

const useContextStore = create<ContextStore>((set) => ({
  context: CONTEXT.NONE,
  setContext: (context: CONTEXT) => set({ context: context }),
}));

export default useContextStore;
