import { Routes, Route, useNavigate } from "react-router-dom";
import "nes.css/css/nes.min.css";
import Main from "pages/Main";
import LnAddressInput from "pages/LnAddressInput";
import AuthCode from "pages/AuthCode";
import MyPage from "pages/MyPage";
import usePriceStore, { useExchangeRateStore } from "store/priceStore";
import { useEffect, useRef, useState } from "react";
import { fetchKrwUsdRate, initMexcWebSocket, initUpbitWebSocket } from "api/Exchange";
import useSystemStatusStore from "store/systemStatusStore";
import { api, initS2SWebSocket } from "api/Shit2Sat";
import { STATUS } from "const/Constants";
import { deleteToken } from "localStorage/token";
import { formatPrice } from "util/Fomatter";
import CustomDialog, { DIALOG_TYPE } from "components/dialog/CustomDialog";

function App() {
  const {
    mexcWebSocket,
    setMexcWebSocket,
    setBitcoin,
    setTron,
    upbitWebSocket,
    setUpbitWebSocket,
    setBitcoinKRW,
    setTronKRW,
    bitcoin,
  } = usePriceStore();
  const { setKrwUsdRate } = useExchangeRateStore();
  const { setStatus, s2sWebSocket, setS2sWebSocket, setChannelCapacity } =
    useSystemStatusStore();
  const calledInit = useRef(false);
  const navigate = useNavigate();
  const [pageTitle, setPagetTitle] = useState("Shit2Sat");
  const ref = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    //TODO: App.tsx is initialized twice
    if (calledInit.current === false) {
      api.setGlobalErrorHandler(() => {
        alert("Unauthorized request. Please verify your LN address.");
        deleteToken();
        navigate("/");
      });

      api
        .healthCheck()
        .then((response) => {
          if (response.data.status === "ok")
            setStatus(
              checkStatus(response.data) ? STATUS.ACTIVE : STATUS.INACTIVE
            );
        })
        .catch((e) => {
          setStatus(STATUS.INACTIVE);
          ref.current?.showModal();
        });

      calledInit.current = true;
      setMexcWebSocket(initMexcWebSocket(setBitcoin, setTron));
      setUpbitWebSocket(initUpbitWebSocket(setBitcoinKRW, setTronKRW));
      setS2sWebSocket(initS2SWebSocket(setChannelCapacity));

      fetchKrwUsdRate()
        .then((krwExchangeRate) => {
          // console.log("KRW Exchange Rate:", krwExchangeRate);
          setKrwUsdRate(krwExchangeRate);
        })
        .catch((error) => {
          console.error("Error fetching exchange rate:", error);
        });

    }

    return () => {
      if (
        mexcWebSocket != null ||
        upbitWebSocket != null ||
        s2sWebSocket != null
      ) {
        mexcWebSocket?.close();
        upbitWebSocket?.close();
        s2sWebSocket?.close();
      }
    };
  }, []);

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  useEffect(() => {
    if (bitcoin)
      setPagetTitle(
        formatPrice(bitcoin?.toString(), 2) + " USD/BTC | 💩Shit2Sat⚡️"
      );
  }, [bitcoin]);

  const checkStatus = (data: { info: any }) => {
    for (const service in data.info) {
      if (data.info.hasOwnProperty(service)) {
        if (data.info[service].status !== "up") {
          return false;
        }
      }
    }
    return true;
  };

  return (
    <>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/auth-step-1" element={<LnAddressInput />} />
        <Route path="/auth-step-2" element={<AuthCode />} />
        <Route path="/mypage" element={<MyPage />} />
      </Routes>
      <CustomDialog
        ref={ref}
        type={DIALOG_TYPE.INFO}
        message={"Sorry. The system is undergoing maintenance."}
        buttonName="CLOSE"
        onClickButton={() => {
          ref.current?.close();
        }}
      />
    </>
  );
}

export default App;
