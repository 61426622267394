const LN = "LN";
const TRON = "TRON";

export const saveLnAddress = (address: string) => {
  localStorage.setItem(LN, address);
};

export const getLnAddress = (): string | null => {
  return localStorage.getItem(LN);
};

export const saveTronAddress = (address: string) => {
  localStorage.setItem(TRON, address);
};

export const getTronAddress = (): string | null => {
  return localStorage.getItem(TRON);
};
