import Banner from "components/Banner";
import { STATUS } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";
import { isUser } from "localStorage/token";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import usePriceStore from "store/priceStore";
import useSystemStatusStore from "store/systemStatusStore";
import { getDepositRange, getMaxDepositForBtc } from "util/DepositRange";
import { formatPrice } from "util/Fomatter";

const Header = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const { status, channelCapacity } = useSystemStatusStore();
  const { bitcoin, tron } = usePriceStore();

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipMobileVisible, setTooltipMobileVisible] = useState(false);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0 ? true : false);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    if (status === STATUS.ACTIVE) {
      if (isUser()) {
        navigate("/mypage");
        return;
      }

      navigate("/auth-step-1");
    }
  };

  const handleGoToHomeClick = () => {
    if (location.pathname === "/") {
      return;
    }
    navigate("/");
  };

  const handleMouseEnter = () => {
    if (!isMobileDevice()) {
      setTooltipVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobileDevice()) {
      setTooltipVisible(false);
    }
  };

  const handleClick = () => {
    if (isMobileDevice()) {
      setOpacity(1);
      setTooltipMobileVisible(!tooltipMobileVisible);

      // MOBILE: tooltip fade-out
      setTimeout(() => {
        let opacity = 1;
        const intervalId = setInterval(() => {
          opacity -= 0.1;
          if (opacity <= 0) {
            clearInterval(intervalId);
            setTooltipMobileVisible(false);
          }
          setOpacity(opacity);
        }, 30);
      }, 5000);
    }
  };

  // Check if the device is a mobile device
  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  return (
    <div>
      <header
        className="App-header"
        style={{
          //
          position: "fixed",
          background: "#fff",
          width: "100%",
          top: 0,
          zIndex: 100,
        }}
      >
        <div
          style={{
            paddingRight: "20px",
            paddingLeft: "20px",
            maxWidth: "1290px",
            margin: "0 auto",
            marginTop: "12px",
            marginBottom: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <section className="menu is-horizontal">
            <div
              className="menu-label"
              style={{
                fontSize: isScrolled ? "1.5rem" : "1.8rem",
                transition: "font-size 0.3s",
                marginRight: "10px",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              Shit2Sat
              <img
                src={
                  status === STATUS.ACTIVE
                    ? "/image/status-active.png"
                    : "/image/status-inactive.png"
                }
                alt="system status"
                style={{
                  width: isScrolled ? "24px" : "32px",
                  height: isScrolled ? "24px" : "32px",
                  marginBottom: "8px",
                  transition: "width 0.3s, height 0.3s",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              />
            </div>
            <div className="menu-list">
              <a
                className="menu-item"
                href="#!"
                onClick={handleGoToHomeClick}
                style={{
                  fontSize: isScrolled ? "0.9rem" : "1.2rem",
                  transition: "font-size 0.3s",
                  color: status === STATUS.ACTIVE ? "#219CEF" : "Highlight",
                }}
              >
                HOME
              </a>
              <a
                className="menu-item"
                href="#!"
                onClick={handleButtonClick}
                style={{
                  fontSize: isScrolled ? "0.9rem" : "1.2rem",
                  transition: "font-size 0.3s",
                  color: status === STATUS.ACTIVE ? "#219CEF" : "Highlight",
                  marginLeft: "16px",
                }}
              >
                MY_PAGE
              </a>
            </div>
          </section>

          <a
            href="https://t.me/+ROq6O_mq77xjZTNl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="nes-avatar is-large pixelated-image"
              alt="Telegram"
              src="/image/telegram.png"
              style={{
                width: isScrolled ? "48px" : "64px",
                height: isScrolled ? "48px" : "64px",
                transition: "width 0.3s, height 0.3s",
              }}
            />
          </a>
          {/* <a
            href="mailto:shit2s.at@gmail.com"
            rel="noopener noreferrer"
          >
            <img
              className="nes-avatar is-large pixelated-image"
              alt="email"
              src="/image/mail.png"
              style={{
                width: isScrolled ? "42px" : "56px",
                height: isScrolled ? "42px" : "56px",
                transition: "width 0.3s, height 0.3s",
              }}
            />
          </a> */}
        </div>
        {width > 900 ? (
          <div
            style={{
              height: 52,
              background: "#131313",
            }}
          >
            <div
              style={{
                maxWidth: "1290px",
                display: "flex",
                margin: "0 auto",
                alignItems: "center",
                paddingTop: 16,
                paddingLeft: 20,
              }}
            >
              <p style={{ color: "#f7931a", fontSize: 14 }}>BTC</p>
              <p style={{ color: "white", fontSize: 13, marginLeft: 12 }}>
                {bitcoin ? "$" + formatPrice(bitcoin, 2) : "....."}
              </p>
              <p style={{ color: "#B73E31", fontSize: 14, marginLeft: 12 }}>
                TRX
              </p>
              <p style={{ color: "white", fontSize: 13, marginLeft: 12 }}>
                {tron ? "$" + formatPrice(tron, 4) : "....."}
              </p>
              <p
                style={{
                  color: "lightblue",
                  fontSize: 14,
                  marginLeft: 12,
                  letterSpacing: -1,
                }}
              >
                Valid One-Time Deposit
              </p>
              <p style={{ color: "white", fontSize: 13, marginLeft: 12 }}>
                {tron && bitcoin
                  ? `${getDepositRange(tron).min} ~ ${getMaxDepositForBtc(
                      tron,
                      bitcoin,
                      "0.0099"
                    )}`
                  : "....."}
                <span style={{ marginLeft: 4 }}>trx</span>
              </p>

              <p style={{ color: "yellow", fontSize: 14, marginLeft: 12 }}>
                Capacity
              </p>
              <p style={{ color: "white", fontSize: 13, marginLeft: 12 }}>
                {channelCapacity ? formatPrice(channelCapacity) : "....."}
                <span style={{ marginLeft: 4 }}>sats</span>
              </p>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                height: 36,
                background: "#131313",
              }}
            >
              <Banner>
                <div
                  style={{
                    maxWidth: "1290px",
                    display: "flex",
                    margin: "0 auto",
                    alignItems: "center",
                    paddingTop: 10,
                    paddingLeft: 20,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p
                      style={{
                        color: "lightblue",
                        fontSize: 12,
                      }}
                    >
                      Deposit(trx)
                    </p>

                    <p
                      className="nes-text is-warning"
                      style={{ fontSize: 12, marginLeft: 12 }}
                    >
                      min.
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontSize: 10,
                        marginLeft: 12,
                        marginTop: 2,
                      }}
                    >
                      {tron ? `${getDepositRange(tron).min}` : ""}
                    </p>
                    <p
                      className="nes-text is-warning"
                      style={{ fontSize: 12, marginLeft: 12 }}
                    >
                      max.
                    </p>
                    <p
                      style={{
                        color: "white",
                        fontSize: 10,
                        marginLeft: 12,
                        marginTop: 2,
                      }}
                    >
                      {tron && bitcoin
                        ? `${getMaxDepositForBtc(tron, bitcoin, "0.0099")}`
                        : ""}{" "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    maxWidth: "1290px",
                    display: "flex",
                    margin: "0 auto",
                    alignItems: "center",
                    paddingTop: 10,
                    paddingLeft: 20,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <p style={{ color: "yellow", fontSize: 12 }}>Capacity</p>
                    <p
                      style={{
                        color: "white",
                        fontSize: 10,
                        marginLeft: 12,
                        marginTop: 2,
                      }}
                    >
                      {channelCapacity ? formatPrice(channelCapacity) : "....."}
                      <span style={{ marginLeft: 4 }}>sats</span>
                    </p>
                  </div>
                </div>
              </Banner>
            </div>
            <div
              style={{
                height: 36,
                background: "#131313",
              }}
            >
              <div
                style={{
                  maxWidth: "1290px",
                  display: "flex",
                  margin: "0 auto",
                  alignItems: "center",
                  paddingTop: 4,
                  paddingLeft: 8,
                }}
              >
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#f7931a", fontSize: 12, marginLeft: 12 }}>
                    BTC
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontSize: 10,
                      marginLeft: 12,
                      marginTop: 2,
                    }}
                  >
                    {bitcoin ? "$" + formatPrice(bitcoin, 2) : ""}
                  </p>
                </div>
                <div style={{ display: "flex" }}>
                  <p style={{ color: "#B73E31", fontSize: 12, marginLeft: 12 }}>
                    TRX
                  </p>
                  <p
                    style={{
                      color: "white",
                      fontSize: 10,
                      marginLeft: 12,
                      marginTop: 2,
                    }}
                  >
                    {tron ? "$" + formatPrice(tron, 4) : ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
      {(tooltipVisible || tooltipMobileVisible) && (
        <div
          className="nes-balloon is-rounded"
          style={{
            zIndex: 1000,
            position: "fixed",
            left: isMobileDevice() ? 50 : 200,
            top: 50,
            maxWidth: isMobileDevice() ? 310 : 380,
            opacity: opacity,
          }}
        >
          <p style={{ fontSize: isMobileDevice() ? 11 : 14 }}>
            {status === STATUS.ACTIVE
              ? "The system is running."
              : "The system is undergoing maintenance."}
          </p>
        </div>
      )}
    </div>
  );
};

export default Header;
