import {
  useState,
  ChangeEvent,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Layout from "components/layout/Layout";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import Transaction, { TransactionProps } from "components/Transaction";
import { useWindowSize } from "hook/useWindowSize";
import CopyDialog, {
  unsecuredCopyToClipboard,
} from "components/dialog/CopyDialog";
import { MOBILE_WIDTH, STATUS } from "const/Constants";
import WarningDialog from "components/dialog/WarningDialog";
import Button from "components/Button";
import useSystemStatusStore from "store/systemStatusStore";
import { getTokenTimestamp, isUser, removeToken } from "localStorage/token";
import { api } from "api/Shit2Sat";
import CustomDialog, { DIALOG_TYPE } from "components/dialog/CustomDialog";
import ProgressBar from "components/ProgressBar";
import { saveTronAddress } from "localStorage/address";
import useContextStore, { CONTEXT } from "store/contextStore";
import usePriceStore from "store/priceStore";
import { getDepositRange, getMaxDepositForBtc } from "util/DepositRange";
import TwoButtonDialog from "components/dialog/TwoButtonDialog";
import { hideDialogNextTime, showDialog } from "localStorage/dialog";

const MyPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { status } = useSystemStatusStore();
  const setContext = useContextStore((state) => state.setContext);
  const { tron, bitcoin } = usePriceStore();

  const initialized = useRef(false);

  const dialogRef = useRef<HTMLDialogElement>(null);
  const copyDialogRef = useRef<HTMLDialogElement>(null);

  const [selectedOption, setSelectedOption] = useState("addresses");
  const [lnAddress, setLnAddress] = useState("");
  const [tronAddress, setTronAddress] = useState("");

  const [loading, setLoading] = useState(false);
  const [txs, setTxs] = useState<Array<TransactionProps> | null>(null);

  const ref = useRef<HTMLDialogElement>(null);
  const [dialogType, setDialogType] = useState(DIALOG_TYPE.ERROR);
  const [errorMessage, setErrorMessage] = useState("");

  /**
   * dialog for [check deposit] button guide.
   */
  const guideRef = useRef<HTMLDialogElement>(null);
  const [guideVisible, setGuideVisible] = useState(true);

  const [visible, setVisible] = useState(false);
  const [clickable, setClickable] = useState(true);

  const minTronCount = useMemo<string | null>(() => {
    if (!tron) return null;

    return getDepositRange(tron).min;
  }, [tron]);

  const maxTronCount = useMemo<string | null>(() => {
    if (!tron || !bitcoin) return null;

    return getMaxDepositForBtc(tron, bitcoin, "0.0099");
  }, [tron, bitcoin]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (!isUser()) {
        navigate("/auth-step-1");
        return;
      }

      api
        .getUser()
        .then((response) => {
          setLnAddress(response.data.lightningAddress);
          setTronAddress(response.data.tronAddress);
        })
        .catch(console.log);

      api
        .getRecentTxs()
        .then((response) => {
          setTxs(response.data);
        })
        .catch(console.log);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (showDialog()) {
      guideRef.current?.showModal();
    } else {
      setGuideVisible(false);
    }
  }, []);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const renderContent = () => {
    return (
      <div className={"nes-container is-dark"} style={{ marginTop: "20px" }}>
        {selectedOption === "addresses" ? renderAddresses() : renderTxs()}
      </div>
    );
  };

  const handleRenewAddressButtonClick = () => {
    if (status === STATUS.ACTIVE) {
      dialogRef.current?.showModal();
    }
  };

  const handleRenewTronAddress = () => {
    const tokenTimestamp = getTokenTimestamp();
    if (!tokenTimestamp) {
      console.assert(true, "this should not happen.");
      return;
    }

    const currentTime = new Date();
    if (
      currentTime.getTime() - tokenTimestamp.getTime() <
      24 * 60 * 60 * 1000
    ) {
      //Immediate reissue of tron addresses
      //if it's been less than 24 hours since tokens were issued
      handleCreateTronAddressButtonClick();
      return;
    }

    // set global state to renew tron and go to auth process
    setContext(CONTEXT.REREW_TRON);
    navigate("/auth-step-1");
  };

  // CHECK DEPOSIT BUTTON CLICKED
  const handleButtonClick = () => {
    setLoading(true);
    setClickable(false);
    api
      .checkDeposit()
      .then((response) => {
        if (response.data.newTxIds && response.data.newTxIds.length > 0) {
          api
            .getRecentTxs()
            .then((response) => {
              setTxs(response.data);
              setDialogType(DIALOG_TYPE.INFO);
              setErrorMessage("We've found a new deposit!");
              ref.current?.showModal();
            })
            .catch(console.log)
            .finally(() => {
              setSelectedOption("transactions");
              return;
            });

          return;
        }
        setDialogType(DIALOG_TYPE.INFO);
        setErrorMessage("New deposit is not found!");
        ref.current?.showModal();
      })
      .catch((e) => {
        setDialogType(DIALOG_TYPE.ERROR);
        if (e?.response) {
          setErrorMessage(e.response?.data.message);
          ref.current?.showModal();
        } else if (e.request) {
          alert(`No response received...`);
        } else {
          alert("Error occured before reqeusting");
        }
        // console.log(e.response);
      })
      .finally(() => {
        setLoading(false);
      });

    setTimeout(() => {
      setClickable(true);
    }, 30000);
  };

  const handleHelpButtonClick = () => {
    setVisible(true);
  };

  const handleCopyClick = async () => {
    try {
      if (window.isSecureContext && navigator.clipboard) {
        await navigator.clipboard.writeText(tronAddress);
      } else {
        unsecuredCopyToClipboard(tronAddress);
      }

      if (width > MOBILE_WIDTH) {
        copyDialogRef.current?.showModal();
      }
    } catch (error) {
      console.error("An error occured while copying text... ", error);
      alert(error);
    }
  };

  const handleCreateTronAddressButtonClick = () => {
    setLoading(true);
    api
      .createWallet()
      .then((response) => {
        const tronAddress = response?.data.address;
        setTronAddress(tronAddress);
        saveTronAddress(tronAddress);
      })
      .catch((error) => {
        if (error?.response) {
          setErrorMessage(error.response?.data.message);
          ref.current?.showModal();
        } else if (error.request) {
          alert(`No response received...`);
        } else {
          alert("Error occured before requesting");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderAddresses = () => {
    return (
      <div>
        {width > MOBILE_WIDTH ? (
          <div>
            <div
              style={{
                backgroundColor: "#212529",
                padding: "1rem",
              }}
              className="nes-field is-inline"
            >
              <label htmlFor="ln_address" style={{ color: "#fff" }}>
                Lightning Network
              </label>
              <input
                type="text"
                id="ln_address"
                className="nes-input is-dark"
                value={lnAddress}
                readOnly
              />
            </div>

            {tronAddress && tronAddress !== "" ? (
              <div>
                <div
                  style={{
                    backgroundColor: "#212529",
                    padding: "1rem",
                  }}
                  className="nes-field is-inline"
                >
                  <label htmlFor="tron_address" style={{ color: "#fff" }}>
                    TRON
                  </label>
                  <input
                    type="text"
                    id="tron_address"
                    className="nes-input is-dark"
                    value={tronAddress}
                    readOnly
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "12px",
                  }}
                >
                  <QRCode
                    value={tronAddress}
                    size={248}
                    style={{ backgroundColor: "white", padding: "14px" }}
                  />
                  <button
                    className="nes-btn"
                    onClick={handleCopyClick}
                    style={{ marginTop: 20 }}
                  >
                    COPY
                  </button>
                </div>
              </div>
            ) : (
              renderInstruction()
            )}

            {tronAddress && tronAddress !== "" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <button
                  className={
                    status !== STATUS.ACTIVE ? "nes-btn is-disabled" : "nes-btn"
                  }
                  onClick={handleRenewAddressButtonClick}
                  disabled={status !== STATUS.ACTIVE}
                >
                  CLICK HERE TO RENEW ADDRESS
                </button>
              </div>
            )}
          </div>
        ) : (
          // mobile
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              wordBreak: "break-all",
            }}
          >
            <p
              className="nes-text"
              style={{ color: "#fff", fontSize: 14, marginBottom: 8 }}
            >
              Lightning Network
            </p>
            <p className="nes-text is-primary" style={{ fontSize: 14 }}>
              {lnAddress}
            </p>

            {tronAddress && tronAddress !== "" ? (
              <div>
                <p
                  className="nes-text"
                  style={{ color: "#fff", fontSize: 14, marginBottom: 8 }}
                >
                  Tron
                </p>
                <p style={{ fontSize: 10 }}>{tronAddress}</p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    padding: "10px",
                  }}
                >
                  <QRCode
                    value={tronAddress}
                    size={200}
                    style={{ backgroundColor: "white", padding: "14px" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "12px",
                  }}
                >
                  <button
                    className="nes-btn"
                    onClick={handleCopyClick}
                    style={{ fontSize: 14 }}
                  >
                    COPY
                  </button>
                </div>
              </div>
            ) : (
              renderInstruction()
            )}

            {tronAddress && tronAddress !== "" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "32px",
                  wordBreak: "break-word",
                }}
              >
                <button
                  className={
                    status !== STATUS.ACTIVE ? "nes-btn is-disabled" : "nes-btn"
                  }
                  onClick={handleRenewAddressButtonClick}
                  style={{ fontSize: 12 }}
                  disabled={status !== STATUS.ACTIVE}
                >
                  CLICK HERE TO RENEW ADDRESS
                </button>
              </div>
            )}
          </div>
        )}
        <CopyDialog ref={copyDialogRef} />
        <WarningDialog
          ref={dialogRef}
          handleYesButtonClick={handleRenewTronAddress}
          handleNoButtonClick={() => {
            dialogRef.current?.close();
          }}
        />
      </div>
    );
  };

  const renderInstruction = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          padding: width > MOBILE_WIDTH ? "10px" : 0,
        }}
      >
        <div className="nes-container is-rounded is-dark">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              wordBreak: "break-word",
            }}
          >
            <div className="nes-badge">
              <span className="is-warning">
                !!
                <img
                  src="/image/poop.png"
                  alt="copy"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                />
                !!
              </span>
            </div>
            <p
              style={{
                marginTop: 20,
                fontSize: width > MOBILE_WIDTH ? 16 : 12,
              }}
            >
              You don't have a Tron address yet.
            </p>
            <p
              style={{
                fontSize: width > MOBILE_WIDTH ? 16 : 12,
              }}
            >
              Why not create an address and
            </p>
            <p
              style={{
                marginBottom: 20,
                fontSize: width > MOBILE_WIDTH ? 16 : 12,
              }}
            >
              start using Shit2sat right away?
            </p>
            <button
              className={"nes-btn"}
              onClick={handleCreateTronAddressButtonClick}
              style={{ fontSize: width > MOBILE_WIDTH ? 16 : 12 }}
              disabled={status !== STATUS.ACTIVE}
            >
              GENERATE TRON ADDRESS
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderTxs = () => {
    return (
      <div style={{ height: "600px", overflow: "auto" }}>
        {txs && txs.length > 0 ? (
          txs.map((tx, index) => <Transaction tx={tx} key={index} />)
        ) : (
          <div>
            <div className="nes-container is-rounded is-dark">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  wordBreak: "break-word",
                }}
              >
                <img
                  src="/image/icon.png"
                  alt="copy"
                  style={{
                    width: width > MOBILE_WIDTH ? "52px" : "36px",
                    height: width > MOBILE_WIDTH ? "52px" : "36px",
                    marginTop: "2px",
                    cursor: "pointer",
                  }}
                />

                <p
                  style={{
                    fontSize: width > MOBILE_WIDTH ? 16 : 12,
                    marginTop: "20px",
                    textAlign: width > MOBILE_WIDTH ? "center" : "left",
                  }}
                >
                  You haven't tried the service yet.
                </p>
                <p
                  style={{
                    fontSize: width > MOBILE_WIDTH ? 16 : 12,
                    textAlign: width > MOBILE_WIDTH ? "center" : "left",
                  }}
                >
                  Deposit to your Tron address and we'll switch it straight to
                  Satoshi.
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: width > MOBILE_WIDTH ? "flex-end" : "center",
                marginTop: "18px",
              }}
            >
              <button
                className={"nes-btn"}
                onClick={() => {
                  setSelectedOption("addresses");
                }}
                style={{ fontSize: width > MOBILE_WIDTH ? 16 : 12 }}
              >
                SHOW TRON ADDRESS
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const DepositRange = useCallback(() => {
    return (
      <div
        className="nes-container is-dark"
        style={{
          display: "flex",
          alignItems: width > MOBILE_WIDTH ? "center" : "left",
          flexDirection: width > MOBILE_WIDTH ? "row" : "column",
          height: 100,
          marginBottom: 12,
          fontSize: width > MOBILE_WIDTH ? 18 : 12,
        }}
      >
        <p
          style={{
            fontSize: width > MOBILE_WIDTH ? 18 : 10,
            marginTop: width > MOBILE_WIDTH ? 10 : 0,
          }}
        >
          One-time Deposit Range
        </p>
        <p
          className="nes-text is-warning"
          style={{
            marginLeft: width > MOBILE_WIDTH ? 20 : 0,
            marginTop: width > MOBILE_WIDTH ? 10 : -8,
          }}
        >
          {minTronCount && maxTronCount
            ? `${minTronCount} trx ~ ${maxTronCount} trx`
            : "...."}
        </p>
        <Button
          className="nes-btn"
          label="?"
          onClick={handleHelpButtonClick}
          disabled={loading || tronAddress === ""}
          style={{
            marginLeft: "auto",
            fontSize: width > MOBILE_WIDTH ? 18 : 12,
            position: "absolute",
            top: 20,
            right: 20,
          }}
        />
        {visible && (
          <div
            style={{
              position: "absolute",
              top: 70,
              right: 0,
              width: width > MOBILE_WIDTH ? "50%" : "90%",
              height: "100%",
              zIndex: 100,
            }}
          >
            <div className="nes-container is-dark">
              <div style={{ display: "flex" }}>
                <Button
                  className="nes-btn"
                  label="X"
                  onClick={() => {
                    setVisible(false);
                  }}
                  style={{ position: "absolute", right: 20 }}
                />
              </div>
              <p
                style={{
                  color: "white",
                  marginRight: 24,
                  marginTop: 10,
                  fontSize: width > MOBILE_WIDTH ? 14 : 11,
                }}
              >
                The current minimum deposit is{" "}
                <span className="nes-text is-warning">
                  {minTronCount ? minTronCount : "..."} TRX
                </span>
                <span>{ minTronCount && minTronCount === '50' ? '.':
                  `, which is equivalent to 5.5$.`}</span>
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: width > MOBILE_WIDTH ? 14 : 11,
                }}
              >
                If you have deposited less than the minimum amount or more than
                the maximum amount, please contact us on{" "}
                <span className="nes-text is-primary">Telegram</span>.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }, [width, minTronCount, maxTronCount, visible, loading, tronAddress]);

  if (!initialized) {
    return (
      <Layout>
        <div
          className="nes-container"
          style={{
            display: "flex",
            minHeight: 400,
            alignItems: "center",
            padding: 100,
          }}
        >
          <ProgressBar />
        </div>
      </Layout>
    );
  }
  return (
    <Layout>
      <DepositRange />
      {width > MOBILE_WIDTH ? (
        <div>
          <div className="nes-container">
            <div>
              <div>
                <label>
                  <input
                    type="radio"
                    className="nes-radio"
                    name="answer"
                    value="addresses"
                    checked={selectedOption === "addresses"}
                    onChange={handleRadioChange}
                  />
                  {selectedOption === "addresses" ? (
                    <span className="nes-text">ADDRESSES</span>
                  ) : (
                    <span className="nes-text is-disabled">ADDRESSES</span>
                  )}
                </label>

                <label>
                  <input
                    type="radio"
                    className="nes-radio"
                    name="answer"
                    value="transactions"
                    checked={selectedOption === "transactions"}
                    onChange={handleRadioChange}
                  />
                  {selectedOption === "transactions" ? (
                    <span className="nes-text">TRANSACTIONS</span>
                  ) : (
                    <span className="nes-text is-disabled">TRANSACTIONS</span>
                  )}
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label="CHECK DEPOSIT"
                  onClick={handleButtonClick}
                  disabled={loading || !clickable}
                />
              </div>
            </div>
            {renderContent()}
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              label="CHECK DEPOSIT"
              onClick={handleButtonClick}
              disabled={loading || !clickable}
            />
          </div>
          <div style={{ marginTop: "24px" }}>
            <label>
              <input
                type="radio"
                className="nes-radio"
                name="answer"
                value="addresses"
                checked={selectedOption === "addresses"}
                onChange={handleRadioChange}
              />
              {selectedOption === "addresses" ? (
                <span className="nes-text">ADDRESSES</span>
              ) : (
                <span className="nes-text is-disabled">ADDRESSES</span>
              )}
            </label>

            <label>
              <input
                type="radio"
                className="nes-radio"
                name="answer"
                value="transactions"
                checked={selectedOption === "transactions"}
                onChange={handleRadioChange}
              />
              {selectedOption === "transactions" ? (
                <span className="nes-text">TRANSACTIONS</span>
              ) : (
                <span className="nes-text is-disabled">TRANSACTIONS</span>
              )}
            </label>
          </div>
          {renderContent()}
        </div>
      )}
      <CustomDialog
        ref={ref}
        type={dialogType}
        message={errorMessage}
        buttonName="CLOSE"
        onClickButton={() => {
          ref.current?.close();
        }}
      />
      { guideVisible && 
        <TwoButtonDialog 
          ref={guideRef}
          type={DIALOG_TYPE.INFO}
          message={`After sending TRON to the address, click "CHECK DEPOSIT" button.`}
          rightBtnName="CLOSE"
          onClickRightBtn={() => {
            guideRef.current?.close();
          }}
          leftBtnName="Do not show again"
          onClickLeftBtn={() => {
            hideDialogNextTime();
            setGuideVisible(false);
          }}
      />
      }
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            width: "80%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ProgressBar />
        </div>
      )}
      {process.env.NODE_ENV === "development" && (
        <Button
          label="REMOVE TOKEN"
          onClick={() => {
            removeToken();
            navigate("/");
          }}
        />
      )}
    </Layout>
  );
};

export default MyPage;
