// CopyDialog.tsx
// When COPY button or image clicked, this dialog will be shown.
import { MOBILE_WIDTH } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";
import { forwardRef, Ref } from "react";

export const unsecuredCopyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  document.body.prepend(textArea);
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (error) {
    console.error(error);
  } finally {
    textArea.remove();
  }
};

const CopyDialog = forwardRef((_, ref: Ref<HTMLDialogElement>) => {
  const { width } = useWindowSize();

  return (
    <dialog className="nes-dialog" ref={ref}>
      <form method="dialog">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="nes-badge" style={{ marginBottom: "28px" }}>
            <span className="is-primary">SUCCESS</span>
          </div>
          <p
            style={{
              textAlign: "center",
              fontSize: width > MOBILE_WIDTH ? 18 : 12,
            }}
          >
            Your tron address's copied to clipboard.
          </p>
          <button className="nes-btn" style={{ marginTop: "20px" }}>
            CLOSE
          </button>
        </div>
      </form>
    </dialog>
  );
});

export default CopyDialog;
