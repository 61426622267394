import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useWindowSize } from "hook/useWindowSize";

const Layout = (props: { children: React.ReactNode }) => {
  const { width } = useWindowSize();
  return (
    <>
      <Header />

      <main>
        <div
          style={{
            padding: "20px",
            maxWidth: "1280px",
            margin: "0 auto",
            marginTop: width > 1290 ? "140px" : "170px",
          }}
        >
          {props.children}
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Layout;
