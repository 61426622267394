import axios from "axios";
import { getToken } from "localStorage/token";
import { setValue } from "store/systemStatusStore";

const apiUrl = process.env["REACT_APP_BACKEND_URL"];
const wss = process.env["REACT_APP_BACKEND_WSS"];

const header = {
  headers: { "Content-Type": `application/json` },
};
const getHeaderWithAuthroization = () => {
  return {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": `application/json`,
    },
  };
};

export const api = {
  setGlobalErrorHandler: (callbackWhenUnauthorized: () => void) => {
    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          if (error.config.url == apiUrl + '/v1/auth/lightning-address/verify-auth-number') {
            // Return a resolved Promise to bypass the error handling
            return Promise.reject(error);
          }
          callbackWhenUnauthorized();
        }
        return Promise.reject(error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
      }
    );
  },
  post: async (url: string, data: any, _header: object) =>
    axios.post(apiUrl + url, JSON.stringify(data), _header).catch((e) => {
      throw e;
    }),
  get: async (url: string, _header: object) => {
    return axios.get(apiUrl + url, _header).catch((e) => {
      throw e;
    });
  },
  healthCheck: () =>
    api.get("/v1/health", header).catch((e) => {
      throw e;
    }),
  requestAuthCode: async (lnAddress: string) =>
    api.post(
      "/v1/auth/lightning-address/send-auth-number",
      {
        lightningAddress: lnAddress,
      },
      header
    ),
  resetDailyAuthLimit: async (lnAddress: string) =>
    api.post(
      "/v1/auth/reset-daily-limit",
      {
        lightningAddress: lnAddress,
      },
      header
    ),
  verifyAuthNumber: async (lnAddress: string, authNumber: string) =>
    api.post(
      "/v1/auth/lightning-address/verify-auth-number",
      {
        lightningAddress: lnAddress,
        authNumber: authNumber,
      },
      header
    ),
  createWallet: async () =>
    api.post("/v1/wallet", {}, getHeaderWithAuthroization()),
  getUser: async () => api.get("/v1/users", getHeaderWithAuthroization()),
  checkDeposit: async () =>
    api.post(
      "/v1/transactions/check-shit-deposit",
      {},
      getHeaderWithAuthroization()
    ),
  getRecentTxs: async () =>
    api.get("/v1/transactions/recent", getHeaderWithAuthroization()),
  getFeeRate: async () => api.get("/v1/transactions/fee", header),
  // the symbol value should be either BTCUSDC or TRXUSDC
  getMarketPrice: async (symbol: string) => api.get(`/v1/market/${symbol.toUpperCase()}`, header),
};

/*
enum WebSocketEventEnum {
  PING = "PING",
  LIGHTNING_INFO = "LIGHTNING_INFO",
}

interface WebSocketDataDto {
  timestamp: Date;
  dataType: WebSocketDataTypeEnum;
  data: any;
}

enum WebSocketDataTypeEnum {
  PONG = "PONG",
  LIGHTNING_INFO = "LIGHTNING_INFO",
  BITCOIN_TRANSFER = "BITCOIN_TRANSFER",
}

Response
1) PONG: { }
2) LIGHTNING_INFO:
{
  satsBalance: number;
}
3) BITCOIN_TRANSFER: NOT IMPLEMENTED
*/

export const initS2SWebSocket = (setChannelCapacity: setValue): WebSocket => {
  const ws = new WebSocket(wss as string);

  ws.onopen = () => {
    try {
      ws.send(`{PING = "PING", LIGHTNING_INFO = "LIGHTNING_INFO"}`);
    } catch (e) {
      console.error("ws open error >> ", e);
    }
  };

  ws.onmessage = (e) => {
    try {
      if (e.data) {
        const blob = new Blob([e.data], { type: "application/json" });
        blob.text().then((text) => {
          const jsonObject = JSON.parse(text);
          switch (jsonObject.dataType) {
            case "LIGHTNING_INFO":
              setChannelCapacity(jsonObject.data.satsBalance + "");
              return;
            case "PONG":
              // TODO
              return;
            case "BITCOIN_TRANSFER":
              // TODO
              return;
            default:
              return;
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return ws;
};
