import useSystemStatusStore from "store/systemStatusStore";
import { STATUS } from "const/Constants";

const Button = ({
  label,
  onClick,
  disabled = false,
  className,
  style,
}: {
  label: any;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  style?: object;
}) => {
  const { status } = useSystemStatusStore();

  const getButtonClassName = () => {
    if (status !== STATUS.ACTIVE) {
      return "nes-btn is-disabled";
    } else if (!disabled) {
      return "nes-btn is-warning"; // yellow button
    } else {
      return "nes-btn is-disabled";
    }
  };

  return (
    <button
      className={className ? className : getButtonClassName()}
      onClick={onClick}
      disabled={status !== STATUS.ACTIVE || disabled}
      style={{ ...style }}
    >
      {label}
    </button>
  );
};

export default Button;
