const NAME = "access_token";

export const saveToken = (token: string) => {
  const currentTime = new Date();
  const tokenData = {
    token: token,
    timestamp: currentTime.getTime(),
  };
  // console.log(JSON.stringify(tokenData));
  localStorage.setItem(NAME, JSON.stringify(tokenData));
};

export const getToken = (): string | null => {
  const tokenDataString = localStorage.getItem(NAME);
  if (tokenDataString) {
    const tokenData = JSON.parse(tokenDataString);
    return tokenData.token;
  }
  return null;
};

export const getTokenTimestamp = (): Date | null => {
  const tokenDataString = localStorage.getItem(NAME);
  if (tokenDataString) {
    const tokenData = JSON.parse(tokenDataString);
    return new Date(tokenData.timestamp);
  }
  return null;
};

// if token exsits, it considers as a user.
export const isUser = (): boolean => {
  const value = getToken();
  return !(
    value == null ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const removeToken = () => {
  localStorage.setItem(NAME, "");
};

export const deleteToken = () => {
  localStorage.removeItem(NAME);
};
