import { MOBILE_WIDTH } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";
import { ChangeEvent, forwardRef, Ref, useState } from "react";

type HandleYesButtonClick = () => void;
type HandleNoButtonClick = () => void;

interface WarningDialogProps {
  handleYesButtonClick: HandleYesButtonClick;
  handleNoButtonClick: HandleNoButtonClick;
}

const WarningDialog = forwardRef<HTMLDialogElement, WarningDialogProps>(
  (
    { handleYesButtonClick, handleNoButtonClick },
    ref: Ref<HTMLDialogElement>
  ) => {
    const { width } = useWindowSize();
    const [isChecked, setIsChecked] = useState({
      first: false,
      second: false,
    });

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { name, checked } = event.target;
      setIsChecked((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    };

    return (
      <dialog className="nes-dialog" ref={ref}>
        <form method="dialog">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              wordBreak: "break-word",
              maxWidth: 560,
            }}
          >
            <div className="nes-badge" style={{ marginBottom: "28px" }}>
              <span className="is-error">!!WARNING!!</span>
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: width > MOBILE_WIDTH ? 18 : 14,
              }}
            >
              I understood that
            </p>
            <div>
              <label>
                <input
                  type="checkbox"
                  className="nes-checkbox"
                  name="first"
                  checked={isChecked.first}
                  onChange={handleCheckboxChange}
                />
                <span
                  style={{
                    fontSize: width > MOBILE_WIDTH ? 14 : 11,
                    letterSpacing: -1,
                  }}
                >
                  Once I change the address, it cannot be reversed.
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  className="nes-checkbox"
                  name="second"
                  checked={isChecked.second}
                  onChange={handleCheckboxChange}
                />
                <span
                  style={{
                    fontSize: width > MOBILE_WIDTH ? 14 : 11,
                    letterSpacing: -1,
                  }}
                >
                  According to the Tron policy, 1.1 TRX will be charged for
                  account creation and activation when the first deposit to a
                  new address.
                </span>
              </label>
            </div>

            <div style={{ display: "flex", marginTop: 24 }}>
              <button
                className="nes-btn"
                onClick={() => {
                  setIsChecked({ first: false, second: false });
                  handleNoButtonClick();
                }}
                style={{ width: 100 }}
              >
                NO
              </button>
              <div style={{ width: 8 }} />
              <button
                className={
                  !isChecked.first || !isChecked.second
                    ? "nes-btn is-disabled"
                    : "nes-btn is-primary"
                }
                onClick={handleYesButtonClick}
                style={{ width: 100 }}
                disabled={!isChecked.first || !isChecked.second}
              >
                YES
              </button>
            </div>
          </div>
        </form>
      </dialog>
    );
  }
);

export default WarningDialog;
