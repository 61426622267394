import { create } from "zustand";

export type SetPrice = (price: string) => void;

interface PriceStore {
  mexcWebSocket: WebSocket | null;
  setMexcWebSocket: (ws: WebSocket) => void;
  upbitWebSocket: WebSocket | null;
  setUpbitWebSocket: (ws: WebSocket) => void;
  bitcoin: string | null;
  setBitcoin: SetPrice;
  tron: string | null;
  setTron: SetPrice;
  bitcoinKRW: string | null;
  setBitcoinKRW: SetPrice;
  tronKRW: string | null;
  setTronKRW: SetPrice;
  error: boolean;
  setError: (state: boolean) => void;
}

interface ExchangeRateStore {
  trxBtcRate: string | null;
  setTrxBtcRate: SetPrice;
  krwUsdRate: string | null;
  setKrwUsdRate: SetPrice;
}

const usePriceStore = create<PriceStore>((set) => ({
  mexcWebSocket: null,
  setMexcWebSocket: (ws: WebSocket) => set({ mexcWebSocket: ws }),
  upbitWebSocket: null,
  setUpbitWebSocket: (ws: WebSocket) => set({ mexcWebSocket: ws }),
  bitcoin: null,
  setBitcoin: (price: string) => set({ bitcoin: price }),
  tron: null,
  setTron: (price: string) => set({ tron: price }),
  bitcoinKRW: null,
  setBitcoinKRW: (price: string) => set({ bitcoinKRW: price }),
  tronKRW: null,
  setTronKRW: (price: string) => set({ tronKRW: price }),
  error: false,
  setError: (state: boolean) => set({ error: state }),
}));

const useExchangeRateStore = create<ExchangeRateStore>((set) => ({
  trxBtcRate: null,
  setTrxBtcRate: (rate: string) => {set({ trxBtcRate: rate })},
  krwUsdRate: null,
  setKrwUsdRate: (price: string) => set({ krwUsdRate: price }),
}));

usePriceStore.subscribe(
  (state) => {
    const { bitcoin, tron } = state;
    if (bitcoin !== null && tron !== null) {
      const trxBtcRate = (parseFloat(tron) / parseFloat(bitcoin)).toString();
      useExchangeRateStore.setState({ trxBtcRate });
    }
  },
);

export default usePriceStore;
export { useExchangeRateStore }; 
