import { MOBILE_WIDTH } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";
import { forwardRef, Ref } from "react";

type HandleButtonClick = () => void;
export enum DIALOG_TYPE {
  INFO,
  ERROR,
}

interface TwoButtonDialog {
  type?: DIALOG_TYPE;
  message: string;
  rightBtnName: string;
  onClickRightBtn: HandleButtonClick;
  leftBtnName: string;
  onClickLeftBtn: HandleButtonClick;
}

const TwoButtonDialog = forwardRef<HTMLDialogElement, TwoButtonDialog>(
  (
    {
      message,
      rightBtnName,
      onClickRightBtn,
      leftBtnName,
      onClickLeftBtn,
      type = DIALOG_TYPE.ERROR,
    },
    ref: Ref<HTMLDialogElement>
  ) => {
    const { width } = useWindowSize();

    return (
      <dialog className="nes-dialog" ref={ref}>
        <form method="dialog">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              wordBreak: "break-word",
              maxWidth: 560,
            }}
          >
            <div className="nes-badge" style={{ marginBottom: "28px" }}>
              <span
                className={
                  type === DIALOG_TYPE.ERROR ? "is-error" : "is-primary"
                }
              >
                {type === DIALOG_TYPE.ERROR ? "!!ERROR!!" : "!!INFO!!"}
              </span>
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: width > MOBILE_WIDTH ? 18 : 14,
              }}
            >
              {message}
            </p>

            <div style={{ marginTop: 24, textAlign: "center" }}>
            {
             width > MOBILE_WIDTH ? <>
              <button
                className="nes-btn"
                onClick={onClickLeftBtn}
                style={{ marginRight: 12 }}
              >
                {leftBtnName}
              </button>
              <button
                className="nes-btn"
                onClick={onClickRightBtn}
                style={{ width: 120 }}
              >
                {rightBtnName}
              </button>
             </> : <>
              <button
                className="nes-btn"
                onClick={onClickRightBtn}
                style={{ width: 120 }}
              >
                {rightBtnName}
              </button>
              <div onClick={onClickLeftBtn} style={{marginTop: 12, fontSize: 12, textDecoration: 'underline'}}>{leftBtnName}</div>
             </>
            }
              
            </div>
          </div>
        </form>
      </dialog>
    );
  }
);

export default TwoButtonDialog;
