import { formatPrice } from "./Fomatter";

// currently tron
export const getDepositRange = (tronPrice: string) => {
  if (!tronPrice) {
    return { min: null, max: null, unit: "trx" };
  }

  return {
    min: 5.5 >= 50 * parseFloat(tronPrice) ? 
          formatPrice(Math.ceil(5.5 / parseFloat(tronPrice)).toString(), 0) : '50',
    max: formatPrice(Math.floor(999 / parseFloat(tronPrice)).toString(), 0),
    unit: "trx",
  };
};

export const getMaxDepositForBtc = (tronPrice: string, btcPrice: string, btcLimit: string): string => {
  return formatPrice(Math.floor(parseFloat(btcLimit) * parseFloat(btcPrice) / parseFloat(tronPrice)).toString(), 0);
}
