import { useState, useEffect } from "react";

const CountdownTimer = ({
  second,
  running,
  onFinish,
}: {
  second: number;
  running: boolean;
  onFinish: () => void;
}) => {
  const [timeLeft, setTimeLeft] = useState(second);

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    if (running) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime === 0) {
            clearInterval(timer);
            onFinish();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else {
      if (timer) {
        clearInterval(timer);
        return;
      }
    }

    return () => clearInterval(timer);
  }, [running]);

  const getClassName = () => {
    switch (true) {
      case timeLeft < 60 && timeLeft >= 30:
        return "is-success";
      case timeLeft < 30 && timeLeft >= 5:
        return "is-warning";
      case timeLeft < 5:
        return "is-error";
      default:
        return "is-primary";
    }
  };

  // mm:ss format
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div className="nes-badge">
        <span className={getClassName()}>
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </span>
      </div>
    </div>
  );
};

export default CountdownTimer;
