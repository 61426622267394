import { MOBILE_WIDTH } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";
import { forwardRef, Ref } from "react";

type HandleButtonClick = () => void;
export enum DIALOG_TYPE {
  INFO,
  ERROR,
}

interface CustomDialogProps {
  type?: DIALOG_TYPE;
  message: string;
  buttonName: string;
  onClickButton: HandleButtonClick;
}

const CustomDialog = forwardRef<HTMLDialogElement, CustomDialogProps>(
  (
    { message, buttonName, onClickButton, type = DIALOG_TYPE.ERROR },
    ref: Ref<HTMLDialogElement>
  ) => {
    const { width } = useWindowSize();

    return (
      <dialog className="nes-dialog" ref={ref}>
        <form method="dialog">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              wordBreak: "break-word",
              maxWidth: 560,
            }}
          >
            <div className="nes-badge" style={{ marginBottom: "28px" }}>
              <span
                className={
                  type === DIALOG_TYPE.ERROR ? "is-error" : "is-primary"
                }
              >
                {type === DIALOG_TYPE.ERROR ? "!!ERROR!!" : "!!INFO!!"}
              </span>
            </div>
            <p
              style={{
                textAlign: "center",
                fontSize: width > MOBILE_WIDTH ? 18 : 14,
              }}
            >
              {message}
            </p>

            <div style={{ marginTop: 24 }}>
              <button
                className="nes-btn"
                onClick={onClickButton}
                style={{ width: 120 }}
              >
                {buttonName}
              </button>
            </div>
          </div>
        </form>
      </dialog>
    );
  }
);

export default CustomDialog;
