//Rename Mexc.ts to Exchange.ts

import { SetPrice } from "store/priceStore";

export const initMexcWebSocket = (
  setBitcoinPrice: SetPrice,
  setTronPrice: SetPrice,
): WebSocket => {
  const ws = new WebSocket("wss://wbs.mexc.com/ws");

  const param1 = "spot@public.deals.v3.api@BTCUSDC";
  const param2 = "spot@public.deals.v3.api@TRXUSDC";
  // const param3 = "spot@public.deals.v3.api@TRXBTC";

  ws.onopen = () => {
    ws.send(
      `{ "method":"SUBSCRIPTION", "params":["${param1}", "${param2}"] }`
    );
  };

  /**
   * >> In the response, if the msg received is same as request params, this means the request sent was a success.
   * {"id":0,"code":0,"msg":"spot@public.deals.v3.api@TRXUSDC,spot@public.deals.v3.api@BTCUSDC"}
   *
   * >> market stream result
   * {"c":"spot@public.deals.v3.api@TRXUSDC","d":{"deals":[{"p":"0.11390","v":"261.86","S":2,"t":1706251608781}],"e":"spot@public.deals.v3.api"},"s":"TRXUSDC","t":1706251608783}
   * {"c":"spot@public.deals.v3.api@BTCUSDC","d":{"deals":[{"p":"40068.48","v":"0.000190","S":1,"t":1706251609825}],"e":"spot@public.deals.v3.api"},"s":"BTCUSDC","t":1706251609826}
   */
  ws.onmessage = (e) => {
    const obj = JSON.parse(e.data.toString());

    if (obj.s === "TRXUSDC") {
      setTronPrice(obj.d.deals[0].p);
      // console.log(">> TRXUSDC:", obj.d.deals[0].p)
    } else if (obj.s === "BTCUSDC") {
      setBitcoinPrice(obj.d.deals[0].p);
      // console.log(">> BTCUSDC:", obj.d.deals[0].p)
    } 
  };

  return ws;
};

export const initUpbitWebSocket = (
  setBitcoinKPrice: SetPrice,
  setTronKRW: SetPrice
) => {
  const upbitWs = new WebSocket("wss://api.upbit.com/websocket/v1");

  upbitWs.onopen = () => {
    upbitWs.send(
      '[{"ticket":"prices"},{"type":"ticker","codes":["KRW-BTC", "KRW-TRX"]}]'
    );
  };

  upbitWs.onmessage = (e) => {
    try {
      if (e.data) {
        const blob = new Blob([e.data], { type: "application/json" });

        blob.text().then((text) => {
          const data = JSON.parse(text);
          const price = data["trade_price"] || 0;

          switch (data["code"]) {
            case "KRW-BTC":
              setBitcoinKPrice(price.toString());
              return;
            case "KRW-TRX":
              setTronKRW(price.toString());
              return;
          }
        });
      }
    } catch (e) {
      upbitWs.close();
    }
  };
  // upbitWs.onerror = (e) => {};
  // upbitWs.onclose = (e) => {};

  return upbitWs;
};

export const fetchKrwUsdRate = async () => {
  try {
    const response = await fetch(
      "https://api.manana.kr/exchange/rate.json?base=KRW&code=USD"
    );
    const data = await response.json();
    const krwExchangeRate = data[0].rate;
    return krwExchangeRate;
  } catch (error) {
    console.error("Error fetching exchange rate:", error);
    throw error;
  }
};