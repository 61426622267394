const NAME = "showDialog";

export const hideDialogNextTime = () => {
    localStorage.setItem(NAME, 'false');
  };
  
export const showDialog = (): boolean => {
    const showModalFromLocalStorage = localStorage.getItem(NAME);
    if (showModalFromLocalStorage === 'false') {
      return false;
    }
    return true;
};

export const reset = () => {
    localStorage.removeItem(NAME);
}
