import { useState, useEffect } from "react";

function ProgressBar() {
  const [count, setCount] = useState(0);
  const [increment, setIncrement] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (increment) {
        setCount((prevCount) => prevCount + 1);
      } else {
        setCount((prevCount) => prevCount - 1);
      }

      if (count === 0) {
        setIncrement(true);
      } else if (count === 100) {
        setIncrement(false);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [count, increment]);

  return (
    <progress className="nes-progress is-pattern" value={count} max="100" />
  );
}

export default ProgressBar;
