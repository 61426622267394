import { MOBILE_WIDTH } from "const/Constants";
import { useWindowSize } from "hook/useWindowSize";

const Footer = () => {
  const { width } = useWindowSize();

  return (
    <div
      style={{
        backgroundColor: "#c3c3c3",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "20px",
          maxWidth: "1290px",
          margin: "0 auto",
          marginTop: "60px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <footer style={{ position: "relative", height: "100px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <h4
              style={{
                textAlign: "center",
                color: "white",
                fontSize: width > MOBILE_WIDTH ? "16px" : "12px",
              }}
            >
              ©2024 Shit2Sat / Somewhere on the Earth
            </h4>
            <p
              style={{
                textAlign: "center",
                color: "white",
                fontSize: width > MOBILE_WIDTH ? "16px" : "12px",
              }}
            >
              We Are All Satoshi.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
